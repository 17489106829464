<template>
  <v-dialog
    v-model="visibility"
    max-width="500"
    content-class="cross__dialog"
    :persistent="changed"
    :transition="$dialogTransition"
    @click:outside="showConfirmDialog"
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        {{ `${getDialogTitle} ${$t("paymentPlan.plan")}` }}
      </v-card-title>
      <v-card-text class="pb-3">
        <div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                color="success"
                v-model="inputPlan.name"
                hide-details="auto"
                :label="$t('form.title')"
                :maxLength="30"
                :error-messages="nameErrors"
                :readonly="permissions.can_only_see_plans"
                @keypress="validationForTitle($event)"
                @paste="validationForTitle($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                color="success"
                v-model="inputPlan.price_delivery"
                hide-details="auto"
                :label="$t('paymentPlan.payment_plans_delivery_price')"
                :error-messages="priceDeliveryErrors"
                :maxLength="8"
                :readonly="permissions.can_only_see_plans"
                @keypress="validationForPrice($event)"
                @paste="validationForPrice($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                outlined
                dense
                hide-details="auto"
                color="success"
                v-model="inputPlan.price_hour_meter"
                :label="$t('paymentPlan.payment_plans_hours_price')"
                :maxLength="8"
                :error-messages="priceHoursErrors"
                :readonly="permissions.can_only_see_plans"
                @keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                dense
                hide-details="auto"
                color="success"
                v-model="inputPlan.price_month_meter"
                :label="$t('paymentPlan.price_month_meter')"
                :maxLength="8"
                :error-messages="priceMonthErrors"
                :readonly="permissions.can_only_see_plans"
                @keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                color="success"
                v-model="inputPlan.price_day_crm"
                hide-details="auto"
                :label="$t('paymentPlan.price_day_crm')"
                :error-messages="priceCrmErrors"
                :maxLength="8"
                :readonly="permissions.can_only_see_plans"
                @keypress="validationForPrice($event)"
                @paste="validationForPrice($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                outlined
                dense
                counter
                v-model="inputPlan.description"
                color="success"
                hide-details="auto"
                :rows="4"
                :maxlength="125"
                :label="$t('form.description')"
                :readonly="permissions.can_only_see_plans"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="8" class="ml-1 pb-1 pt-0" v-if="type === 'edit'">
              <v-checkbox
                v-model="inputPlan.is_default"
                color="success"
                hide-details
                :label="$t('paymentPlan.by_default')"
                :readonly="inputPlan.is_default == '1'"
                :false-value="'0'"
                :true-value="'1'"
                :disabled="permissions.can_only_see_plans"
                @change="onChangeDefault"
              ></v-checkbox>
            </v-col>
            <v-col cols="8" class="ml-1 pt-1">
              <v-checkbox
                v-model="inputPlan.is_hidden"
                color="success"
                hide-details
                :label="$t('paymentPlan.is_hidden')"
                :false-value="'0'"
                :true-value="'1'"
                :disabled="permissions.can_only_see_plans"
                @change="changeHide"
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn dark block class="br-10 text-transform-none" @click="showConfirmDialog">{{
              $t("btn.cancel")
            }}</v-btn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
              block
              v-if="type === 'edit' && !permissions.manager"
              class="success-bg text-white text-transform-none br-10"
              :disabled="!changed"
              :loading="loading"
              @click="onUpdate"
              >{{ $t("btn.update") }}</v-btn
            >
            <v-btn
              block
              v-else-if="type === 'create'"
              class="success-bg text-white text-transform-none br-10"
              :disabled="!changed"
              :loading="loading"
              @click="onCreate"
              >{{ $t("btn.create") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onCancel"
    />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import notifications from "@/mixins/notifications";
import user from "@/mixins/user";
import EventBus from "@/events/EventBus";
import { required } from "vuelidate/lib/validators";
import { validationForNumbers, validationForPrice, validationForTitle } from "@/mixins/helpers";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";

export default {
  mixins: [notifications, user],
  components: { ConfirmLeftDialog },
  props: {
    visible: {
      require: true
    },
    plan: {
      require: true,
      type: Object
    },
    type: {
      require: true
    }
  },
  validations: {
    inputPlan: {
      name: { required },
      price_delivery: { required },
      price_hour_meter: { required },
      price_month_meter: { required },
      price_day_crm: { required }
    }
  },
  data: () => ({
    loading: false,
    changed: false,
    inputPlan: {},
    copyPlan: {},
    visibleConfirm: false
  }),
  mounted() {
    this.onMounted();
  },
  methods: {
    validationForNumbers,
    validationForPrice,
    validationForTitle,
    onMounted() {
      this.loading = true;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.plan) {
        if (this.plan[key] === null) {
          this.plan[key] = "";
        }
      }
      if (this.type === "edit") {
        this.inputPlan = { ...this.plan };
        this.copyPlan = { ...this.plan };
      }
      this.loading = false;
    },
    changeHide() {
      this.inputPlan.is_hidden == "1"
        ? (this.copyPlan.is_hidden = "0")
        : (this.copyPlan.is_hidden = "1");
    },
    onCancel() {
      this.$emit("close");
      this.$v.$reset();
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onCancel();
    },
    async onChangeDefault() {
      this.loading = true;
      try {
        if (this.inputPlan.is_default === "1") {
          const result = await paymentPlansService.usersPlansSetDefault(this.inputPlan.uuid);
          EventBus.$emit("on-close-dialog", result);
          EventBus.$emit("on-init", result);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("uuid", this.inputPlan.uuid);
          formData.append("is_hidden", this.inputPlan.is_hidden);
          const changedData = this.$getChangedData(this.inputPlan, this.copyPlan);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          const result = await paymentPlansService.usersPlansUpdate(formData);
          EventBus.$emit("on-close-dialog");
          EventBus.$emit("on-modified", result);
          this.$v.$reset();
          this.setSuccessNotification(this.$t("profile.successfully_updated"));
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("name", this.inputPlan.name);
          if (this.inputPlan.description) {
            formData.append("description", this.inputPlan.description);
          }
          formData.append("price_delivery", this.inputPlan.price_delivery);
          formData.append("price_hour_meter", this.inputPlan.price_hour_meter);
          formData.append("price_month_meter", this.inputPlan.price_month_meter);
          formData.append("price_day_crm", this.inputPlan.price_day_crm);
          formData.append("is_hidden", this.inputPlan.is_hidden);
          const result = await paymentPlansService.usersPlansAdd(formData);
          EventBus.$emit("on-close-dialog");
          EventBus.$emit("on-init", result);
          this.$v.$reset();
          this.setSuccessNotification(this.$t("supply.successfully_created"));
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.inputPlan.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputPlan.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    priceDeliveryErrors() {
      const errors = [];
      if (!this.$v.inputPlan.price_delivery.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputPlan.price_delivery.required &&
        errors.push(this.$t("form.errors.paymentPlansDeliveryPriceRequired"));
      return errors;
    },
    priceHoursErrors() {
      const errors = [];
      if (!this.$v.inputPlan.price_hour_meter.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputPlan.price_hour_meter.required &&
        errors.push(this.$t("form.errors.paymentPlanHoursPriceRequired"));
      return errors;
    },
    priceMonthErrors() {
      const errors = [];
      if (!this.$v.inputPlan.price_month_meter.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputPlan.price_month_meter.required &&
        errors.push(this.$t("form.errors.paymentPlanHoursPriceRequired"));
      return errors;
    },
    priceCrmErrors() {
      const errors = [];
      if (!this.$v.inputPlan.price_day_crm.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputPlan.price_day_crm.required &&
        errors.push(this.$t("form.errors.paymentPlansCrmPriceRequired"));
      return errors;
    },
    getDialogTitle() {
      switch (this.type) {
        case "create":
          return this.$t("paymentPlan.creatingTitle");
        case "edit":
          if (!this.permissions.can_only_see_plans) {
            return this.$t("paymentPlan.updatingTitle");
          } else {
            return this.$t("paymentPlan.seeingTitle");
          }
        default:
          return "--";
      }
    }
  },
  watch: {
    inputPlan: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyPlan, this.inputPlan);
      }
    }
  }
};
</script>
