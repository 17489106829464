<template>
  <div class="mb-2">
    <v-card class="my-2">
      <v-card-title class="py-0 pl-1">
        <v-row
          :class="[
            'align-center',
            'custom-v-row',
            $vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''
          ]"
        >
          <v-col class="custom-v-col">
            <v-col cols="auto">
              {{ $t("paymentPlan.payment_plans") }}
            </v-col>
            <v-col cols="auto">
              <v-text-field
                solo
                dense
                clearable
                class="ml-2"
                ref="searchField"
                maxLength="25"
                hide-details
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('form.search')"
                v-model="searchString"
                @input="onSearchProduct"
              />
            </v-col>
          </v-col>
          <v-col cols="auto">
            <v-row align="center" justify="end">
              <v-col cols="auto" >
                <v-btn
                  v-if="!permissions.can_only_see_plans"
                  depressed
                  class="success-bg text-white text-transform-none br-10"
                  dark
                  @click="onClickCreate"
                  block
                >
                  <v-icon :left="!$vuetify.breakpoint.xs">mdi-plus</v-icon>
                  <div>
                    {{ $t("paymentPlan.new_plan") }}
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-slide-x-reverse-transition>
      <plans-list-table :params="params" />
    </v-slide-x-reverse-transition>
    <PaymentPlansDialog
      :visible="visibleDialog"
      @close="visibleDialog = false"
      :plan="plan"
      :type="dialogType"
      v-if="visibleDialog"
    />
  </div>
</template>

<script>
import _ from "lodash";
import language from "@/mixins/language";
import user from "@/mixins/user";
import notifications from "@/mixins/notifications";
import loader from "@/mixins/loader";
import EventBus from "@/events/EventBus";
import PaymentPlansDialog from "./PaymentPlansDialog.vue";
import PlansListTable from "./PlansListTable.vue";
import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";

export default {
  components: { PaymentPlansDialog, PlansListTable },
  data: () => ({
    params: {},
    dialogType: "",
    visibleDialog: false,
    searchString: "",
    loadTime: 0
  }),
  mixins: [language, notifications, loader, user],
  async mounted() {
    EventBus.$on("openPlansEditDialog", async e => {
      this.openPlansEditDialog(e);
    });
    EventBus.$on("on-close-dialog", async e => {
      this.visibleDialog = false;
    });
    this.checkLoadTime();
  },
  methods: {
    ...mapActions("pageLoadTime", {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime);
    },
    onSearchProduct: _.debounce(function() {
      this.params = {
        uselike: true,
        name: this.searchString
      };
    }, 500),
    openPlansEditDialog(e) {
      this.dialogType = "edit";
      this.plan = e;
      this.visibleDialog = true;
    },
    onClickCreate() {
      this.dialogType = "create";
      this.visibleDialog = true;
      this.plan = {};
    }
  },
  computed: {
    ...mapGetters("pageLoadTime", {
      startTime: getterTypes.GET_START_TIME
    })
  }
};
</script>
