<template>
  <v-row no-gutters align="center" justify="space-between">
    <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''">
      <v-card class="mb-1 pt-2 pb-1">
        <ns-data-table
          :headers="plansHeader"
          class="my-table supply"
          dense
          :request-service="getRequestService"
          :search-params="params"
        >
          <template v-slot:[`header.btn`]>
            <v-icon dense> mdi-cogs</v-icon>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.btn="{ item }">
            <v-btn
              color="primary"
              small
              class="btn-edit"
              @click="openPlansEditDialog(item)"
              :class="{ sm: !isLarge }"
            >
              <v-icon dense> mdi-pencil</v-icon>
            </v-btn>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.name="{ item }">
            <v-row class="custom-v-row flex-nowrap pl-1">
              <span class="font-weight-bold ml-1">
                {{ item.name }}
              </span>
              <v-tooltip
                bottom
                v-if="
                  (item.is_default == '1' && permissions.can_view_default_plan) ||
                    isActivePlan(item)
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" color="yellow darken-2" dense>
                    mdi-star
                  </v-icon>
                </template>
                {{ $t("paymentPlan.active_plan") }}
              </v-tooltip>
            </v-row>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.price_delivery="{ item }">
            <span class="green--text font-weight-bold">
              {{ item.price_delivery }} {{ $t("paymentPlan.uah") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.price_hour_meter="{ item }">
            <span class="green--text font-weight-bold">
              {{ item.price_hour_meter }} {{ $t("paymentPlan.uah") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.price_month_meter="{ item }">
            <span class="green--text font-weight-bold">
              {{ item.price_month_meter }} {{ $t("paymentPlan.uah") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.price_day_crm="{ item }">
            <span class="green--text font-weight-bold">
              {{ item.price_day_crm }} {{ $t("paymentPlan.uah") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.is_hidden="{ item }">
            <span v-if="permissions.can_view_plans_list">
              <v-tooltip bottom v-if="item.is_hidden == '0'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" dense>
                    mdi-eye
                  </v-icon>
                </template>
                {{ $t("paymentPlan.visible_plan") }}
              </v-tooltip>
              <v-tooltip bottom v-if="item.is_hidden == '1'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" dense>
                    mdi-eye-off
                  </v-icon>
                </template>
                {{ $t("paymentPlan.hidden_plan") }}
              </v-tooltip>
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.description="{ item }">
            {{ item.description || "--" }}
          </template>
        </ns-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SizeUi from "@/mixins/SizeUi";
import user from "@/mixins/user";
import EventBus from "@/events/EventBus";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";

export default {
  mixins: [SizeUi, user],
  methods: {
    openPlansEditDialog(e) {
      EventBus.$emit("openPlansEditDialog", e);
    },
    isActivePlan(item) {
      return this.getUser?.uuid_user_plan === item.uuid;
    }
  },
  props: {
    params: {
      require: false
    }
  },
  computed: {
    getRequestService() {
      return paymentPlansService.usersPlansList;
    },
    plansHeader() {
      const result = [
        { text: "#", value: "order", sortable: false },
        {
          text: this.$t("form.title"),
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: `${this.$t("paymentPlan.payment_plans_delivery_price")}`,
          value: "price_delivery",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("paymentPlan.payment_plans_hours_price")}`,
          value: "price_hour_meter",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("paymentPlan.price_month_meter")}`,
          value: "price_month_meter",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("paymentPlan.price_day_crm")}`,
          value: "price_day_crm",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("table.description")}`,
          value: "description",
          align: "left",
          sortable: false
        }
      ];
      if (this.permissions.owner) {
        result.unshift({
          text: "",
          value: "btn",
          align: "center",
          sortable: false
        });
      }
      if (!this.permissions.client) {
        const obj = {
          text: `${this.$t("paymentPlan.visibility")}`,
          value: "is_hidden",
          align: "center",
          sortable: false
        };
        result.splice(result.length - 1, 0, obj);
      }
      return result;
    }
  }
};
</script>
